import React, { Component } from 'react';
import {
	isMobile,
	isBrowser
} from 'react-device-detect';

export default () => {
	return (
		<div style={{marginTop: 10}}>
			<div style={{display: 'flex', marginTop: 10, flexDirection: isMobile ? 'column' : 'inherit'}}>
				<div style={{display: 'block', overflow: 'auto', width: isMobile ? 'inherit' : '33%', marginRight: isMobile ? 'inherit' : 5}}>
					<div style={{marginBottom: isMobile ? 10 : 'inherit', borderRadius: isMobile ? 'inherit' : 5, height: 200, overflow: 'hidden'}}>
						<img style={{position: 'relative', top: '50%', transform: 'translateY(-50%)', width: '100%'}} src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image8.jpg"/>
					</div>
				</div>
				<div style={{width: isMobile ? 'inherit' : '33%', marginLeft: isMobile ? 'inherit' : 5, marginRight: isMobile ? 'inherit' : 5}}>
					<div style={{marginBottom: isMobile ? 10 : 'inherit', borderRadius: isMobile ? 'inherit' : 5, height: 200, overflow: 'hidden'}}>
						<img style={{position: 'relative', top: '50%', transform: 'translateY(-50%)', width: '100%'}} src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image1.jpg"/>
					</div>
				</div>
				<div style={{display: 'block', overflow: 'auto', width: isMobile ? 'inherit' : '33%', marginLeft: isMobile ? 'inherit' : 5}}>
					<div style={{marginBottom: isMobile ? 10 : 'inherit', borderRadius: isMobile ? 'inherit' : 5, height: 200, overflow: 'hidden'}}>
						<img style={{position: 'relative', top: '50%', transform: 'translateY(-50%)', width: '100%'}} src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image4.jpg"/>
					</div>
				</div>
			</div>
		</div>
	)
}