import React, { Component } from 'react';
import {
	isMobile,
	isBrowser
} from 'react-device-detect';

export default () => {
	return (
		<div style={{display: 'flex', marginTop: 10, flexDirection: isMobile ? 'column' : 'inherit'}}>
			<div style={{width: isMobile ? '100%' : '50%', marginRight: isMobile ? 'inherit' : 5}}>
				<div style={{height: isMobile ? 275 : 300, overflow: 'hidden', borderRadius: isMobile ? 'inherit' : 5}}>
					<img 
						src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image5.jpg" 
						style={{
							width: '100%', 
							borderRadius: isMobile ? 'inherit' : 5,
							position: 'relative',
							top: isMobile ? 'inherit' : '50%',
							transform: isMobile ? 'inherit' : 'translateY(-50%)'
						}}
					/>
				</div>
			</div>
			<div style={{display: 'block', overflow: 'auto', width: isMobile ? '100%' : '50%', borderRadius: isMobile ? 'inherit' : 5, marginLeft: isMobile ? 'inherit' : 5}}>
				<div style={{marginTop: isMobile ? 10 : 'inherit', height: isMobile ? 'inherit' : 300, overflow: 'hidden', borderRadius: isMobile ? 'inherit' : 5}}>
					<img 
						src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image2.jpg" 
						style={{
							width: '100%', 
							borderRadius: isMobile ? 'inherit' : 5,
							position: 'relative',
							top: isMobile ? 'inherit' : '50%',
							transform: isMobile ? 'inherit' : 'translateY(-50%)'
						}}
					/>
				</div>
			</div>
		</div>
	)
}