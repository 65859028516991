import React, { Component } from 'react';
import { 
  isMobile,
  isBrowser
} from 'react-device-detect'
import logo from './logo.svg';
import './App.css';

import Header from './components/header.js';
import Section1 from './components/section1.js';
import Section2 from './components/section2.js';
import Section3 from './components/section3.js';
import Section4 from './components/section4.js';
import Section5 from './components/section5.js';
import Section6 from './components/section6.js';

class App extends Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#ffe68a",
          paddingTop: isMobile ? 20 : 50,
          paddingBottom: 100,
          fontFamily: 'avenir, Nunito',
        }}
      >
        <Header/>
        <div 
          style={{
            width: isMobile ? '100%' : '65%', 
            margin: 'auto', 
            marginTop: 10,
            minWidth: isBrowser ? 936 : 'inherit',
          }}>
          <Section1/>
          <Section2/>
          <Section3/>
          <Section4/>
          <Section5/>
          <Section6/>
        </div>
      </div>
    );
  }
}

export default App;
