import React, { Component } from 'react';
import {
	isMobile,
	isBrowser
} from 'react-device-detect';

export default () => {
	return (
		<div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'inherit'}}>
			<div style={{display: 'block', overflow: 'auto', width: isMobile ? '100%' : '33%', backgroundColor: isMobile ? 'inherit' : "#ffdcc6", borderRadius: isMobile ? 'inherit' : 5, marginRight: isMobile ? 0 : 5}}>
				<div style={{padding: 10}}>
					<span style={{fontWeight: 600}}>WHY THAILAND?</span><img style={{width: 20, height: 20, marginLeft: 5, position: 'relative', top: 4}} src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/thailandflag.png"/><br/><br/>
					There is extreme urgency and need to spread the gospel in Thailand.<br/><br/>
					In a country that is heavily influenced by Buddhism, less than 0.1% of the population claims faith in Christ. National pride is very much part of the Thai culture; the people have pictures of the king inside their homes and the city is filled with temples and shrines. <br/><br/>
					Children are put to labor at a young age without a future or a chance for education, while the women are trapped in human trafficking with no way out. Men go out to find work but opportunities are scarce and limited. <br/><br/>
					The war in Burma, located on the border of Thailand, is also a major issue, since most of our Mango House children are from Burma. Their families were forced to flee and set up tribal villages in the borders of Thailand.<br/><br/>
				</div>
			</div>
			<div style={{width: isMobile ? '100%' : '33%', marginLeft: isMobile ? 'inherit' : 5, marginRight: 5}}>
				<img src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image3.jpg" style={{width: '100%', borderRadius: isMobile ? 'inherit' : 5}}/>
				<div style={{margin: '5%', textAlign: 'center'}}>
					"For even as the Son of Man did not come to be served,<br/>
					<span style={{fontWeight: 'bold', fontStyle: 'italic',}}>but to serve,</span><br/>
					and to give his life as a ransom for many."<br/><br/>
					Mark 10:45
				</div>
				<div style={{height: 280, overflow: 'hidden', borderRadius: isMobile ? 'inherit' : 5,}}>
					<img src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image6.jpg" style={{width: '100%', position: 'relative', top: '50%', transform: 'translateY(-50%)'}}/>
				</div>
			</div>
			<div style={{display: 'block', overflow: 'auto', width: isMobile ? '100%' : '33%', backgroundColor: isMobile ? 'inherit' : "#ffdcc6", borderRadius: isMobile ? 'inherit' : 5, marginLeft: isMobile ? 0 : 5}}>
				<div style={{padding: 10}}>
					<span style={{fontWeight: 600}}>WHAT IS MANGO HOUSE?</span><img style={{width: 25, height: 25, marginLeft: 5, position: 'relative', top: 5.5}} src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/mango.jpg"/><br/><br/>
					In 2012, Pastor Danny and his wife Sue built Mango House, an orphanage in a rural area of Chiang Mai, to provide a safe home, a quality education, and most importantly, a Christ-centered community for our kids.<br/><br/>

					<a style={{
						display: 'block',
						textDecoration: 'none',
						border: '2px solid #ffa500',
						padding: 5,
						backgroundColor: 'white',
						textAlign: 'center',
						color: 'black'
					}}target="_blank" href="https://www.elijahfoundation.org/">Elijah Foundation</a><br/><br/>

					In 2018, GCC invited Mango House to America for the first time. We were able to host them for two whole weeks, where they got to experience all kinds of new foods and fun activities, like the Bible Museum, Niagara Falls, and New York City!<br/><br/>
					We were overflowed with generosity and hospitality from the church. GCC members volunteered to sponsor meals, participate in hangouts and pray for the Mango House kids. I hope and pray that we were able to show the kids the meaning of community and discipleship in Christ.
				</div>
			</div>
		</div>
	)
}