import React, { Component } from 'react';
import {
	isMobile,
	isBrowser
} from 'react-device-detect';

export default () => {
	return (
		<div
			style={{
				textAlign: 'center',
				marginTop: 30,
				marginBottom: 30,
			}}
		>
			<span style={{display: isMobile ? 'block' : 'inherit', fontFamily: "Sarina", fontSize: 35, marginBottom: isMobile ? 10 : 0}}>Thank you for your support & prayers!</span><br/>
			I am so happy to take part in this journey with you!<br/>
			I will continually give you updates before, during and after my trip.<br/>
			Thank you!<br/><br/>
			Love,<br/>
			Monica Jeon
			<svg preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" viewBox="30.99997901916504 19.999923706054688 138.0001983642578 160.00013732910156" height="20" width="20" role="img" style={{}}>
			    <g>
			        <path fill="#DA3F34" d="M152.78 24.236c-9.797-6.903-23.567-5.126-32.447 3.56-11.734 11.477-15.272 24.964-23.156 39.579-13.322-12.148-23.113-18.029-38.17-18.98-18.898-1.191-28.494 9.746-27.988 31.228.584 24.667 11.938 42.36 29.965 55.797 11.995 8.942 54.545 40.89 65.582 44.224 3.596 1.087 5.126-.355 7.951-3.776 7.545-9.144 25.305-71.508 31.216-92.156 6.03-21.051 4.793-47.607-12.953-59.476" fillRule="evenodd"></path>
			    </g>
			</svg>
		</div>
	)
}