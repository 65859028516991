import React, { Component } from 'react';
import {
	isMobile,
	isBrowser
} from 'react-device-detect';

export default () => {
	return (
		<div
			style={{
				textAlign: 'center'
			}}
		>
			<div style={{margin: 'auto'}}>
				<div style={{
					textAlign: 'center', 
					padding: 15, 
					paddingBottom: 0, 
					fontFamily: 'avenir, Nunito'
				}}>2019 Thailand Missions</div>
				<div style={{
					textAlign: 'center', 
					padding: 15, 
					fontWeight: 'bold', 
					fontSize: isMobile ? 40 : 50, 
					marginBottom: isMobile ? 5 : 30,
				}}>Mango House!</div>
			</div>
			<div 
				style={{
					height: isMobile ? 250 : 550,
					overflow: 'hidden',
				}}
			>
				<img 
					style={{
						width: isMobile ? '150%' : '100%',
						position: 'relative',
						top: '50%',
						left: isMobile ? '50%' : 'inherit',
						transform: isMobile ? 'translateX(-50%) translateY(-50%)' : 'translateY(-50%)',
					}} 
					src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image11.jpg"
				/>
			</div>
		</div>
	)
}

