import React, { Component } from 'react';
import {
	isMobile,
	isBrowser
} from 'react-device-detect';

export default () => {
	return (
		<div style={{display: 'flex', marginTop: isMobile ? 'inherit' : 10, height: isMobile ? 530 : 300, flexDirection: isMobile ? 'column' : 'inherit'}}>
			<div style={{width: isMobile ? '100%' : '50%', marginRight: isMobile ? 'inherit' : 5}}>
				<div style={{height: 150, overflow: 'hidden', borderRadius: isMobile ? 'inherit' : 5}}>
					<img 
						src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image10.jpg" 
						style={{
							width: '100%', 
							borderRadius: isMobile ? 'inherit' : 5,
							position: 'relative',
							top: '50%',
							transform: 'translateY(-50%)'
						}}
					/>
				</div>
				<div style={{padding: 10, textAlign: 'center'}}>

					"Suppose one of you has a hundred sheep and loses one of them.<br/> Doesn’t he leave the ninety-nine in the open country<br/> <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>and go after the lost sheep until he finds it?"</span><br/><br/>
					Luke 15:4

				</div>
			</div>
			<div style={{display: 'block', overflow: 'hidden', width: isMobile ? '100%' : '50%', borderRadius: isMobile ? 'inherit' : 5, marginLeft: isMobile ? 'inherit' : 5}}>
				<img src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image12.jpg" style={{width: '100%', borderRadius: isMobile ? 'inherit' : 5}}/>
			</div>
		</div>
	)
}