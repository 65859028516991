import React, { Component } from 'react';
import {
	isMobile,
	isBrowser
} from 'react-device-detect'; 

export default () => {
	return (
		<div>
			<div>
				<div style={{display: 'flex', marginTop: 10, flexDirection: isMobile ? 'column' : 'inherit'}}>
					<div style={{display: 'block', overflow: 'auto', width: isMobile ? '100%' : '33%', marginRight: isMobile ? 'inherit' : 5}}>
						<div style={{marginBottom: isMobile ? 10 : 'inherit', borderRadius: isMobile ? 'inherit' : 5, height: 150, overflow: 'hidden'}}>
							<img style={{position: 'relative', top: '50%', transform: 'translateY(-50%)', width: '100%'}} src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image7.jpg"/>
						</div>
					</div>
					<div style={{width: isMobile ? '100%' : '33%', marginLeft: isMobile ? 'inherit' : 5, marginRight: isMobile ? 'inherit' : 5}}>
						<div style={{marginBottom: isMobile ? 10 : 'inherit', borderRadius: isMobile ? 'inherit' : 5, height: 150, overflow: 'hidden'}}>
							<img style={{position: 'relative', top: '50%', transform: 'translateY(-20%)', width: '100%'}} src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image9.jpg"/>
						</div>
					</div>
					<div style={{display: 'block', overflow: 'auto', width: isMobile ? '100%' : '33%', marginLeft: isMobile ? 'inherit' : 5}}>
						<div style={{marginBottom: isMobile ? 10 : 'inherit', borderRadius: isMobile ? 'inherit' : 5, height: 150, overflow: 'hidden'}}>
							<img style={{position: 'relative', top: '50%', transform: isMobile ? 'translateY(-30%)' : 'translateY(-45%)', width: '100%'}} src="https://s3-us-west-2.amazonaws.com/monicajeon-imgs/image13.jpg"/>
						</div>
					</div>
				</div>
			</div>

			<div>
				<div style={{display: 'flex', marginTop: isMobile ? 'inherit' : 10, flexDirection: isMobile ? 'column' : 'inherit'}}>
					<div style={{display: 'block', overflow: 'auto', width: isMobile ? '100%' : '33%', marginRight: 5, borderRadius: isMobile ? 'inherit' : 5, backgroundColor: "#ffdcc6", }}>
						<div style={{padding: 10, marginTop: isMobile ? 'inherit' : 10}}>
							<span style={{fontWeight: 600}}>WILL YOU PARTNER WITH ME?</span><br/><br/>

							<span style={{textDecoration: 'underline'}}>1. Prayer</span><br/>
							Please pray for me and the team as we go out and spread the love of God to our Mango House kids!<br/>
							Specific prayer requests are on the right!<br/><br/>

							<span style={{textDecoration: 'underline'}}>2.Financial Support</span><br/>

							<div style={{marginLeft: 5}}>
								I need your help in raising $1,585.00 for this trip. This includes airfare and room/board expenses. If you are interested in supporting me financially, here are some ways:<br/><br/>
								- Cash/check made out to Grace Community Chapel with "TH2019_16 Monica Jeon" on the memo section along with a filled out <a href="https://docs.wixstatic.com/ugd/43567c_69a7bf9f317249c68d21c2bacf150029.pdf">Contribution Stub</a>. Please give it to me personally.<br/><br/>
								- To support online, please click <a target="_blank" href="https://gracecommunitychapel.wufoo.com/forms/z5vzay21xwmb6r/">here</a>.<br/><br/>
								<span style={{fontSize: 13, fontStyle: 'italic'}}>**(Please note that PayPal receives a fee of 2.9% plus a $0.30 flat fee for each transaction)</span><br/>
							</div>
							
						</div>
					</div>
					<div style={{width: isMobile ? '100%' : '33%', marginLeft: isMobile ? 'inherit' : 5, marginRight: isMobile ? 'inherit' : 5, borderRadius: isMobile ? 'inherit' : 5, backgroundColor: "#ffdcc6", }}>
						<div style={{padding: 10, marginTop: 10}}>
							<span style={{fontWeight: 600}}>PRAYER REQUESTS</span><br/><br/>

							<span style={{textDecoration: 'underline'}}>1. For our pastors and missionaries that are serving faithfully in Thailand.</span><br/>
							Please lift up a prayer for Pastor Danny and his wife Sue, Pastor Matt and Sarah, and Nin'Mai (full-time staff) for God to give them the strength and wisdom to overcome loneliness, rejection, anxiety, disappointments and burdens that they have to face everyday.<br/><br/>
							​
							<span style={{textDecoration: 'underline'}}>2. For our Thailand team.</span><br/>
							We were so blessed with a big team of 27 people including children this year! Please pray for unity of the team, for spiritual and physical protection over us while we are there, and for God to use each and every one of us to be examples of Christ to the kids. <br/><br/>
							​
							<span style={{textDecoration: 'underline'}}>3. For our Mango House kids.</span><br/>
							Some of our Mango house kids do not know and understand God due to past trauma and difficult living circumstances. Please pray that God will be their Shepherd as they battle with temptations, discrimination for not being Thai descent, academic challenges, discipline, and spiritual attacks while attending their Buddhist school. Please pray for deeper intimacy and maturity in their faith.<br/><br/>
						</div>

					</div>
					<div style={{display: 'block', overflow: 'auto', width: isMobile ? '100%' : '33%', marginLeft: isMobile ? 'inherit' : 5, borderRadius: isMobile ? 'inherit' : 5, backgroundColor: "#ffdcc6", }}>
						<div style={{padding: 10, marginTop: 10}}>
							<span style={{fontWeight: 600}}>WHAT WILL I BE DOING THERE?</span><br/><br/>

							Our team will  be at Mango House from March 17-26. During our time there, we will be taking on the following responsibilities:<br/>
							1. Leading a VBS & Youth Group Retreat<br/>
							2. Hosting a carnival & club sessions like knitting and dancing!<br/>
							3. Guiding bible studies and prayer meetings<br/>
							4. Sharing the gospel through devotionals and testimonies<br/><br/>
							This will be my second time at Mango House! Thailand has such a special place in my heart because it was where I felt accepted, comforted and restored. When I was at Mango House away from all the distractions of the world, I was able to see just how happy these kids were. I was fortunate to have everything and yet I was miserable. Why did I feel so empty? It wasn't because I didn't have enough, but because I sought out for happiness in the wrong places.<br/><br/>
							Through the kids at Mango House, I witnessed child-like faith - not just with their words but truly relying on God with their hearts and actions. I strive to be more like them. <br/><br/>

							<span style={{fontSize: 13, fontStyle: 'italic'}}>(Fun Fact: In Thailand, Summer break is in March, so we will pretty much be with the kids all day long!)</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}